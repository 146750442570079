
























.collapsed-block {
  .collapse {
    margin-top: 0.75rem;
  }

  .collapsed-title {
    position: relative;
    cursor: pointer;
    font-weight: 700;
    height: 20px;

    .title {
      position: absolute;
      left: 50%;
      top: 0;
      transform: translate(-50%);
      padding: 0 20px;
      background-color: var(--bg-leftbar);
      text-transform: uppercase;
      letter-spacing: 0.5px;
      font-size: 0.6875rem;
      text-align: center;
    }

    .dropdown-divider {
      position: absolute;
      width: 100%;
      top: 0;
    }
  }
}
