





















.modal-content {
  width: 100%;
  border-radius: 0;
}

.modal-body {
  max-height: 600px;
  padding: 1rem;
  overflow: auto;

  @media screen and (max-width: 767.98px) {
    height: 100vh;
    max-height: calc(100vh - 74px);
  }
}

.modal-footer {
  padding: .75rem;
}
